/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
// import {KTSVG} from '../../../../helpers'
// import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({ id: "MENU.USER.MANAGEMENT" })}</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='/media/icons/duotune/general/gen049.svg'
        title={intl.formatMessage({ id: "MENU.LINK.USER_MANAGEMENT" })}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/tipster-management/tipsters'
        icon='/media/icons/duotune/finance/fin004.svg'
        title={intl.formatMessage({ id: "MENU.LINK.TIPSTER_MANAGEMENT" })}
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({ id: "MENU.LINK.BUSSINES_MANAGEMENT_LABEL" })}</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/apps/competition-management/competition'
        icon='/media/icons/duotune/coding/cod002.svg'
        title={intl.formatMessage({ id: "MENU.LINK.COMPETITION_MANAGEMENT" })}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/participants-management/participants'
        icon='/media/icons/duotune/general/gen022.svg'
        title={intl.formatMessage({ id: "MENU.LINK.PARTICIPANTS_MANAGEMENT" })}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/bookie-management/bookies'
        icon='/media/icons/duotune/general/gen005.svg'
        title={intl.formatMessage({ id: "MENU.LINK.BOOKIE_MANAGEMENT" })}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/country-management/country'
        icon='/media/icons/duotune/abstract/abs024.svg'
        title={intl.formatMessage({ id: "MENU.LINK.COUNTRY_MANAGMENT" })}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/sport-management/sports'
        icon='/media/icons/duotune/abstract/abs034.svg'
        title={intl.formatMessage({ id: "MENU.LINK.SPORT_MANAGMENT" })}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/bet-management/bets'
        icon='/media/icons/duotune/graphs/gra001.svg'
        title={intl.formatMessage({ id: "MENU.LINK.MARKETS_MANAGMENT" })}
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({ id: "HEADER.USER.MENU.SETTINGS" })}</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/apps/tipster-type-management/types'
        icon='/media/icons/duotune/abstract/abs014.svg'
        title={intl.formatMessage({ id: "MENU.LINK.TIPSTER_TYPE_MANAGEMENT" })}
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/apps/discount-management/discounts'
        icon='/media/icons/duotune/graphs/gra007.svg'
        title={intl.formatMessage({ id: "MENU.LINK.DISCOUNTS" })}
        fontIcon='bi-layers'
      />
      {/* <SidebarMenuItem
        to='/apps/service-management/services'
        icon='/media/icons/duotune/finance/fin006.svg'
        title={intl.formatMessage({ id: "AUTH.GENERAL.SERVICES" })}
        fontIcon='bi-layers'
      /> */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({ id: "PICKS.GENERAL.LABEL" })}</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/apps/pick-management/picks'
        icon='/media/icons/duotune/general/gen026.svg'
        title={intl.formatMessage({ id: "AUTH.INPUT.PICKS_MIN" })}
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>{intl.formatMessage({ id: "EVENTS.GENERAL.LABEL" })}</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/apps/event-management/event'
        icon='/media/icons/duotune/general/gen007.svg'
        title={intl.formatMessage({ id: "EVENTS.GENERAL.LABEL" })}
        fontIcon='bi-layers'
      />
    </>
  )
}

export {SidebarMenuMain}
