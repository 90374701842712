import { Sport } from '@apuestes/types';
import { initialSportState } from '../../../../_metronic/helpers';
import { createSlice } from '@reduxjs/toolkit';
import { apuestesAPI } from '../../services/apuestesAPI';

const sportSlice = createSlice({
    name: 'sport',
    initialState: {
        meta: { fetch: false, id: -1 },
        value: initialSportState
    },
    reducers: {
        resetReducer(state, action) {
            if (action.type === 'RESET_STATE') {
                state.value = initialSportState
            }
        }
    },
    extraReducers(builder) {
        builder
            .addMatcher(apuestesAPI.endpoints.getModelById.matchFulfilled, (state, action) => {
                if (action.meta.arg.originalArgs.model === 'sports') {
                    Object.assign(state.value, action.payload as Sport)
                }
            })
            .addMatcher(apuestesAPI.endpoints.updateModel.matchFulfilled, (state, action) => {
                if (action.meta.arg.originalArgs.model === 'sports') {
                    Object.assign(state.value, action.payload as Sport)
                }
            })
    },
});

export default sportSlice.reducer;