import { Event } from "@apuestes/types";
import { initialEventState } from "../../../../_metronic/helpers";
import { createSlice } from "@reduxjs/toolkit";
import { apuestesAPI } from "../../services/apuestesAPI";

const eventSlice = createSlice({
    name: "event",
    initialState: {
        meta: { fetch: false, id: -1 },
        value: initialEventState
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(apuestesAPI.endpoints.getModelById.matchFulfilled, (state, action) => {
                if (action.meta.arg.originalArgs.model === 'events') {
                    state.value = action.payload as Event;
                }
            })
            .addMatcher(apuestesAPI.endpoints.updateModel.matchFulfilled, (state, action) => {
                if (action.meta.arg.originalArgs.model === 'events') {
                    state.value = action.payload as Event;
                }
            })
    }
});

export default eventSlice.reducer;