/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import { useIntl } from "react-intl";
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  const intl = useIntl();
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          {/* begin::Links */}
          {/* <div className='d-flex fw-semibold text-primary fs-base'>
            <a href='#' className='px-5' target='_blank'>
              Terms
            </a>

            <a href='#' className='px-5' target='_blank'>
              Plans
            </a>

            <a href='#' className='px-5' target='_blank'>
              Contact Us
            </a>
          </div> */}
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid vh-100 w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/register-bg.jpg')})`}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column align-items-center justify-content-center py-15 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
          <a href='https://apuestes.com/' className='mb-20'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-white.svg')} className='h-75px' />
          </a>
          {/* end::Logo */}
          
          <div className="mt-20">
            {/* begin::Title */}
            <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
            {intl.formatMessage({ id: "AUTH.LOGIN.TEXT_TITLE" })}
            </h1>
            {/* end::Title */}

            {/* begin::Text */}
            <div className='text-white fs-base text-center'>
              {intl.formatMessage({ id: "AUTH.LOGIN.TEXT" })}
            </div>
            {/* end::Text */}
          </div>
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
