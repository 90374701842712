import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import { ToastContainer } from 'react-toastify';
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {useThemeMode} from './../_metronic/partials'
import {AuthInit} from './modules/auth'

const App = () => {
  const { mode } = useThemeMode()
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              pauseOnHover
              theme={mode === 'dark' ? 'dark' : 'light'}
              />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
}

export default App;
