import { Market } from "@apuestes/types";
import { initialMarketState } from "../../../../_metronic/helpers";
import { createSlice } from "@reduxjs/toolkit";
import { apuestesAPI } from "../../services/apuestesAPI";

const marketSlice = createSlice({
    name: "market",
    initialState: {
        meta: { fetch: false, id: -1 },
        value: initialMarketState
    },
    reducers: {},
    extraReducers(builder) {
        builder
        .addMatcher(apuestesAPI.endpoints.getModelById.matchFulfilled, (state, action) => {
          if (action.meta.arg.originalArgs.model === 'markets') {
              state.value = action.payload as Market;
          }
        })
        .addMatcher(apuestesAPI.endpoints.updateModel.matchFulfilled, (state, action) => {
          if (action.meta.arg.originalArgs.model === 'markets') {
              state.value = action.payload as Market;
          }
        })
      },
});

export default marketSlice.reducer;