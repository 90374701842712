import { createContext, Dispatch, SetStateAction, useEffect, useState } from 'react'
import qs from 'qs'
import { MARKET_QUOTES, toAbsoluteUrl } from '../'
import {
  ID,
  QueryState,
  initialQueryState,
  QueryResponseContextProps,
  TQueryResponseContextProps,
  TTQueryResponseContextProps,
  CountryQueryResponseContextProps,
  SportQueryResponseContextProps,
  CompetitionQueryResponseContextProps,
  ParticipantQueryResponseContextProps,
  BookieQueryResponseContextProps,
  BetQueryResponseContextProps,
  PickQueryResponseContextProps,
  EventQueryResponseContextProps,
  SelectOption,
  ServiceQueryResponseContextProps
} from './models'
import { Bookie, Country, Participant, Sport, TipsterType, User, Event, Market, PickResultType, PickStatus, Discount, Message } from '@apuestes/types';
import axios from 'axios';

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
  return createContext(initialState)
}

function createTipsterResponseContext<T>(initialState: TQueryResponseContextProps<T>) {
  return createContext(initialState)
}

function createTipsterTypeResponseContext<T>(initialState: TTQueryResponseContextProps<T>) {
  return createContext(initialState)
}

function createCountryResponseContext<T>(initialState: CountryQueryResponseContextProps<T>) {
  return createContext(initialState)
}

function createSportResponseContext<T>(initialState: SportQueryResponseContextProps<T>) {
  return createContext(initialState)
}

function createCompetitionResponseContext<T>(initialState: CompetitionQueryResponseContextProps<T>) {
  return createContext(initialState)
}

function createPartiucipantsResponseContext<T>(initialState: ParticipantQueryResponseContextProps<T>) {
  return createContext(initialState)
}

function createBookieResponseContext<T>(initialState: BookieQueryResponseContextProps<T>) {
  return createContext(initialState)
}

function createBetResponseContext<T>(initialState: BetQueryResponseContextProps<T>) {
  return createContext(initialState)
}

function createPickResponseContext<T>(initialState: PickQueryResponseContextProps<T>) {
  return createContext(initialState)
}

function createEventResponseContext<T>(initialState: EventQueryResponseContextProps<T>) {
  return createContext(initialState)
}

function createServiceResponseContext<T>(initialState: ServiceQueryResponseContextProps<T>) {
  return createContext(initialState)
}

function isNotEmpty(obj: unknown) {
  return obj !== undefined && obj !== null && obj !== ''
}

// Example: page=1&items_per_page=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {
  const pagination = qs.stringify(state, {filter: ['page', 'limit'], skipNulls: false})
  const sort = qs.stringify(state, {filter: ['sort', 'order'], skipNulls: false})
  const search = isNotEmpty(state.search)
    ? qs.stringify(state, {filter: ['search'], skipNulls: true})
    : ''

  const filter = state.filter
    ? Object.entries(state.filter as Object)
        .filter((obj) => isNotEmpty(obj[1]))
        .map((obj) => {
          return `${obj[0]}=${obj[1]}`
        })
        .join('&')
    : ''

  return [pagination, sort, search, filter]
    .filter((f) => f)
    .join('&')
    .toLowerCase()
}

function parseRequestQuery(query: string): QueryState {
  const cache: unknown = qs.parse(query)
  return cache as QueryState
}

function calculatedGroupingIsDisabled<T>(isLoading: boolean, data: Array<T> | undefined): boolean {
  if (isLoading) {
    return true
  }

  return !data || !data.length
}

function calculateIsAllDataSelected<T>(data: Array<T> | undefined, selected: Array<ID>): boolean {
  if (!data) {
    return false
  }

  if (window.location.href.includes('/user-management/users') || window.location.href.includes('/tipster-management/tipsters')){
    return data.length > 0 && data.length - 1 === selected.length
  } else {
    return data.length > 0 && data.length === selected.length
  }
}

function groupingOnSelect(
  id: ID,
  selected: Array<ID>,
  setSelected: Dispatch<SetStateAction<Array<ID>>>
) {
  if (!id) {
    return
  }

  if (selected.includes(id)) {
    setSelected(selected.filter((itemId) => itemId !== id))
  } else {
    const updatedSelected = [...selected]
    updatedSelected.push(id)
    setSelected(updatedSelected)
  }
}

function groupingOnSelectAll<T>(
  isAllSelected: boolean,
  setSelected: Dispatch<SetStateAction<Array<ID>>>,
  currentUser: any,
  data?: Array<T & {id?: ID}>
) {
  if (isAllSelected) {
    setSelected([])
    return
  }

  if (!data || !data.length) {
    return
  }
  if (window.location.href.includes('/user-management/users') || window.location.href.includes('/tipster-management/tipsters')){
    const excludeCurrentUser = data.filter((item) => item.id).map((item) => item.id).filter(function(value, index, arr){ 
      return value !== currentUser.id;
    });
    setSelected(excludeCurrentUser)
  } else {
    setSelected(data.filter((item) => item.id).map((item) => item.id))
  }
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

function useDynamicLinks(id: number, type: string) {
  const [response, setResponse] = useState<any>(null)
  const [errors, setErrors] = useState<any>(null)

  const fetchLink = () => {
    return axios.post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${ process.env.REACT_APP_API_DYNAMIC_LINKS_KEY }`,
      {
          "longDynamicLink": `${ process.env.REACT_APP_FIREBASE_DYNAMIC_LINKS }?link=${ process.env.REACT_APP_URI_PICK_LINK }/${ type }?id=${ id }&apn=${ process.env.REACT_APP_BUNDLE_ID }&ibi=${ process.env.REACT_APP_BUNDLE_ID }`
      }
    )
    .then( response => setResponse(response) )
    .catch( errors => setErrors(errors) )
  }

  useEffect( () => {
    const shortLink =async () => {
        await fetchLink()
    }
    shortLink()
    // eslint-disable-next-line
  }, [] )

  return { response, errors }
}

function convertBase64(file: File) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
}

function getPhoto(type: string, name: string | null, size: string = 'small'): string|undefined {
  if (type && name && process.env.REACT_APP_S3_URL) {
    return size ? process.env.REACT_APP_S3_URL + type + '/' + size + '.' + name : process.env.REACT_APP_S3_URL + type + '/' + name
  } else {
    return toAbsoluteUrl(`/media/avatars/blank.png`)
  }
}

const onSendValues = async (
  models: string,
  values: User | TipsterType | Sport | Participant | Country | Bookie | Event | Market | Discount | Message,
  exceptKeys: Array<string|null> = [],
  fn: Function,
  id?: ID
) => {
  cleanModel(values, exceptKeys)
  await fn({ model: models, id: id, payload: values })
}

const onSendMedia = async (
  id: ID,
  propertyName: string,
  models: string,
  fn: Function,
  image: HTMLInputElement
) => {
    if ((image.files as FileList).length === 0)
      return
    else {
      const payload = new FormData();
      payload.append(propertyName, (image.files as FileList)[0])
      await fn({ model: models, id, payload })
    }
}

const cancel = (withRefresh: boolean, refetch: Function, setItem: Function) => {
  if (withRefresh) {
    refetch()
  }
  setItem(undefined)
}

const deleteSelected = (model: string, ids: Array<ID>): Promise<void> => {
  const requests = ids.map((id) => axios.delete(`${process.env.REACT_APP_API_URL}/${model}/${id}`))
  return axios.all(requests).then(() => {})
}

const cleanModel = (model: User | TipsterType | Sport | Participant | Country | Bookie | Event | Market | Discount | Message, exceptKeys: Array<string|null> = []) => {
  // eslint-disable-next-line array-callback-return
  Object.keys(model).forEach((key) => {
    if (
      key === 'id' || 
      key === 'result' || 
      key === '_persist' || 
      key === 'algolia_id' || 
      key === 'expired' || 
      key === 'countries_sports' || 
      key === 'countries_sports_id' || 
      key === 'flag' || 
      key === 'following' || 
      key === 'services' || 
      key === 'country' ||  
      key === 'username' ||  
      key === 'competitions' ||  
      key === 'competition' ||  
      key === 'participants' ||  
      key === 'picks' ||  
      key === 'password' || 
      key === 'auth_provider' ||
      key === 'photo' || 
      key === 'logo' || 
      key === 'countries' || 
      key === 'sports' || 
      key === 'sport' || 
      key === 'logo_id' || 
      key === 'flag_id' || 
      key === 'roles' || 
      key === 'services_sports' || 
      key === 'tipster_types' || 
      key === 'email_verified_at' || 
      key === 'markets_sports' || 
      key === 'last_login' || 
      key === 'photo_id' || 
      key === 'icon_id' || 
      key === 'icon' || 
      key === 'verify_hash' || 
      key === 'balance' || 
      key === 'base' ||  
      key === 'created_at' ||
      key === 'updated_at' ||
      key === 'deleted_at' ||
      key === 'coins' ||
      key === 'followers' ||
      key === 'legacy' ||
      key === 'metrics' ||
      key === 'metrics_id' ||
      key === 'channel_id' ||
      key === 'plans' ||
      key === 'room' ||
      key === 'suscriptions' ||
      key === 'users_tipster_types' ||
      key === 'users_tipsters_types_id' ||
      key === 'metadata' ||
      key === 'active' ||
      key === 'amount' ||
      key === 'percentage' ||
      key === 'tipster' ||
      key === 'users' ||
      key === 'base_id'
    ) {
      if (!exceptKeys.includes(key))
        delete (model as any)[key];
    }
  });
}

const createPayload = (data: URLSearchParams, skip?: Array<string>, method?: string) => {
  const payload = {};
  data.forEach((value, key) => {
    if ( skip && !skip.includes(key) ) {
      let rValue: any = value

      if (JSON.parse(value).length === 0 || value === null) return // early return

      // if ((initialQueryState.filter as any)[key])
      //   delete (initialQueryState.filter as any)[key] // cleaning the url

      if ( value === 'true' || value === 'false' ) {
        rValue = JSON.parse(value)
      }
      
      if ( value.includes('[') && value.includes(']') && value.length > 2 ) {
        rValue = JSON.parse(value)
      }

      if ( key === 'role_names' && value !== '' ) {
        rValue = JSON.parse(value).replace('[', '').replace(']', '').split(',').map( (item:string) => item.toLocaleUpperCase() )
      }

      if ( (key === 'last_login' || key === 'from' || key === 'to') && value !== '' ) {
        rValue = new Date(rValue).toISOString() //Case sensitive ISO 8601
      }

      if ( key === 'tipster_id' && value !== '' ) {
        rValue = +rValue
      }
      Object.assign(payload, { [key]: rValue });
      // delete (initialQueryState.filter as any)[key]
    }
  })

  if ( initialQueryState.filter && (initialQueryState.filter as any)['post'] ) {
    // cleaning the url from post param
    delete (initialQueryState.filter as any)['post']
  }

  if ( initialQueryState.filter && (initialQueryState.filter as any)['tipster_id'] ) {
    // cleaning the url from post param
    delete (initialQueryState.filter as any)['tipster_id']
  }

  return { 
    payload,
    newQuery: ( data.has('page') && data.has('limit') ) 
      ? data.toString()
      : stringifyRequestQuery(initialQueryState)
  };
}

const getColorType = (type: string) => {
  switch (type?.toLocaleLowerCase()) {
    case 'vip': return'dark';
    case 'gold': return'warning';
    case 'premium': return'info';
    case 'freemium': return'primary';
    default: return 'primary';
  }
}

const findReactComponent = (el: any) => {
  for (const key in el) {
    if (key.startsWith('__reactFiber$')) {
      const fiberNode = el[key];

      return fiberNode;
    }
  }
  return null;
};

const getMarketQuotes = (label:string) => {
  const id = MARKET_QUOTES.filter( (item: any) => item.label === label)[0].value
  return id
}

const getSportResultQuotes = (label:string) => {
  const id = transformResultTypesSelect().filter( (item: any) => item.label === label)[0].value
  return id
}

const transformResultTypesSelect = () => {
  let arr: Array<SelectOption> = []
  Object.values(PickResultType).forEach( (item, idx) => {
    arr.push({ label: item, value: idx })
  } )

  return arr;
}

const transformStatusTypesSelect = () => {
  let arr: Array<SelectOption> = []
  Object.values(PickStatus).forEach( (item, idx) => {
    arr.push({ label: item, value: idx })
  } )

  return arr;
}

const getPackageVersion = () => {
  var json_app = require('../../../../package.json');

  return json_app.version
}

export {
  createResponseContext,
  findReactComponent,
  getSportResultQuotes,
  createTipsterResponseContext,
  createSportResponseContext,
  createTipsterTypeResponseContext,
  createCountryResponseContext,
  createEventResponseContext,
  createCompetitionResponseContext,
  createPartiucipantsResponseContext,
  createBookieResponseContext,
  createBetResponseContext,
  createPickResponseContext,
  createServiceResponseContext,
  stringifyRequestQuery,
  parseRequestQuery,
  createPayload,
  onSendValues,
  onSendMedia,
  cancel,
  transformResultTypesSelect,
  transformStatusTypesSelect,
  deleteSelected,
  getColorType,
  getMarketQuotes,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  useDebounce,
  useDynamicLinks,
  isNotEmpty,
  convertBase64,
  getPhoto,
  cleanModel,
  getPackageVersion
}
