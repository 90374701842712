import { Competition } from "@apuestes/types";
import { apuestesAPI } from "../../services/apuestesAPI";
import { createSlice } from "@reduxjs/toolkit";
import { initialCompetitionState } from "../../../../_metronic/helpers";

const competitionSlice = createSlice({
    name: "competition",
    initialState: {
        meta: { fetch: false, id: -1 },
        value: initialCompetitionState
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(apuestesAPI.endpoints.getModelById.matchFulfilled, (state, action) => {
                if (action.meta.arg.originalArgs.model === 'competitions') {
                    state.value = action.payload as Competition;
                }
            })
            .addMatcher(apuestesAPI.endpoints.updateModel.matchFulfilled, (state, action) => {
                if (action.meta.arg.originalArgs.model === 'competitions') {
                    state.value = action.payload as Competition;
                }
            })
    },
});

export default competitionSlice.reducer;