import { Dispatch, SetStateAction } from 'react'

export type ID = undefined | null | number | string

export type PaginationState = {
  page: number
  limit: 2 | 3 | 5 | 10 | 30 | 50 | 100
  links?: Array<{label: string; active: boolean; url: string | null; page: number | null}>
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type Meta = {
  fetch: boolean,
  id: number
}

export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  search?: string
}

export type SelectOption = {
  value: number,
  label: string
}

export type Response<T> = {
  current_page?: number
  page?: number
  pages?: number
  data: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type TipsterResponse<T> = {
  current_page?: number
  page?: number
  pages?: number
  data: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type TipsterTypeResponse<T> = {
  current_page?: number
  page?: number
  pages?: number
  data: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type CountryResponse<T> = {
  current_page?: number
  page?: number
  pages?: number
  data: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type SportResponse<T> = {
  current_page?: number
  page?: number
  pages?: number
  data: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type CompetitionResponse<T> = {
  current_page?: number
  page?: number
  pages?: number
  data: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type ParticipantsResponse<T> = {
  current_page?: number
  page?: number
  pages?: number
  data: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type BookieResponse<T> = {
  current_page?: number
  page?: number
  pages?: number
  data: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type BetResponse<T> = {
  current_page?: number
  page?: number
  pages?: number
  data: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type PickResponse<T> = {
  current_page?: number
  page?: number
  pages?: number
  data: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type QueryState = PaginationState & SortState & FilterState & SearchState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  limit: 50
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export type TQueryResponseContextProps<T> = {
  response?: TipsterResponse<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export type TTQueryResponseContextProps<T> = {
  response?: TipsterTypeResponse<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export type CountryQueryResponseContextProps<T> = {
  response?: CountryResponse<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export type SportQueryResponseContextProps<T> = {
  response?: SportResponse<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export type CompetitionQueryResponseContextProps<T> = {
  response?: CompetitionResponse<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export type ParticipantQueryResponseContextProps<T> = {
  response?: ParticipantsResponse<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export type BookieQueryResponseContextProps<T> = {
  response?: BookieResponse<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export type BetQueryResponseContextProps<T> = {
  response?: PickResponse<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export type PickQueryResponseContextProps<T> = {
  response?: CompetitionResponse<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export type EventQueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export type ServiceQueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export let initialQueryResponse = {
  refetch: () => {},
  isLoading: false,
  query: ''
}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => {},
  onSelectAll: () => {},
  clearSelected: () => {},
  setItemIdForUpdate: () => {},
  isAllSelected: false,
  disabled: false,
}
