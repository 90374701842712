import { FC } from 'react';
import { getPhoto } from '../../helpers';

type Props = {
    type: string,
    name: string,
    extention: string,
    idx?: number
};

const ImageMedia:FC<Props> = ({ type, name, extention, idx }) => {
    return(
        <>
            {
                (['jpg', 'jpeg'].includes(extention) ) && (
                    <a href={ getPhoto(type, name, '') } target="_blank" rel="noopener noreferrer">
                        <img className={ idx && idx > 0 ? 'ms-2' : 'ms-0' } src={ getPhoto(type, name, '') } alt={ name } width="100" height="100" />
                    </a>
                )
            }
        </>
    );
};

const AudioMedia:FC<Props> = ({ type, name, extention, idx }) => {
    return(
        <>
            {
                (['mp3'].includes(extention) ) && (
                    <audio controls>
                        <source src={ getPhoto(type, name, '') } type={"audio/" + extention} />
                    </audio>
                )
            }
        </>
    );
};

export { ImageMedia, AudioMedia };