import React, { FC } from 'react';
import moment from 'moment';
import { useLang } from './Metronici18n';
import { MomentContext } from '../helpers/i18nMoment';

//Langs
import esMessages from './moment/es.json'

type Props = {
    children: React.ReactNode
}

const allMessages = {
    de: {},
    en: {},
    es: esMessages,
    fr: {},
    ja: {},
    zh: {},
}

const MomentLocaleProvider: FC<Props> = ({ children }) => {
    const locale = useLang();
    const messages = allMessages[locale]
    const _moment = moment.updateLocale(locale, messages) // default value
    return(
        <>
            <MomentContext.Provider value={_moment}>{ children }</MomentContext.Provider>
        </>
    )
}

export { MomentLocaleProvider }