import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
// import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
// import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'

const PrivateRoutes = () => {
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const AccountTipsterPage = lazy(() => import('../modules/accounts-tipster/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const TipsterPage = lazy(() => import('../modules/apps/tipster-management/UsersPage'))
  const TipsterTypePage = lazy(() => import('../modules/apps/tipster-type-management/TipsterTypePage'))
  const CountryPage = lazy(() => import('../modules/apps/country-management/CountryPage'))
  const SportPage = lazy(() => import('../modules/apps/sport-management/SportPage'))
  const CompetitionPage = lazy(() => import('../modules/apps/competition-management/CompetitionPage'))
  const ParticipantsPage = lazy(() => import('../modules/apps/participants-management/ParticipantsPage'))
  const BookiesPage = lazy(() => import('../modules/apps/bookie-management/BookiesPage'))
  const BetPage = lazy(() => import('../modules/apps/bet-management/BetPage'))
  const PickPage = lazy(() => import('../modules/apps/picks-management/PickPage'))
  const EventPage = lazy(() => import('../modules/apps/event-management/EventPage'))
  const DiscountPage = lazy(() => import('../modules/apps/discount-management/DiscountPage'))
  const ServicePage = lazy(() => import('../modules/apps/service-management/ServicePage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        {/* Lazy Modules */}
        <Route
          path='user/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='tipster/account/*'
          element={
            <SuspensedView>
              <AccountTipsterPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/tipster-management/*'
          element={
            <SuspensedView>
              <TipsterPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/tipster-type-management/*'
          element={
            <SuspensedView>
              <TipsterTypePage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/country-management/*'
          element={
            <SuspensedView>
              <CountryPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/sport-management/*'
          element={
            <SuspensedView>
              <SportPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/competition-management/*'
          element={
            <SuspensedView>
              <CompetitionPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/participants-management/*'
          element={
            <SuspensedView>
              <ParticipantsPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/bookie-management/*'
          element={
            <SuspensedView>
              <BookiesPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/bet-management/*'
          element={
            <SuspensedView>
              <BetPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/pick-management/*'
          element={
            <SuspensedView>
              <PickPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/event-management/*'
          element={
            <SuspensedView>
              <EventPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/discount-management/*'
          element={
            <SuspensedView>
              <DiscountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/service-management/*'
          element={
            <SuspensedView>
              <ServicePage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
