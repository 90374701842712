import { PaginationState, initialQueryState } from '../index'

export const createLinks = (pages: number, currentPage: any) => {
  let paginatorJump = pages > 5 && pages > 1 ? 5 : pages-1;
  let defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState
  }

  if (pages === 0) return defaultPaginationState

  
  if (+currentPage > 1)
    defaultPaginationState.links?.push({active: false, label: "&laquo; Previous", page: +currentPage!==1?+currentPage-1:null, url: null})
  
  defaultPaginationState.links?.push({active: true, label: `${1}`, page: 1 , url: `/?page=${1}`})
  
  //Adding 3 dots to pagination if current page is bigger than 2
  if (+currentPage > 3){
    defaultPaginationState.links?.push({active: false, label: `...`, page: null , url: null})
  }
  
  if (+currentPage > 2 && +currentPage !== pages && (+currentPage + paginatorJump <= pages )){
    defaultPaginationState.links?.push(...createFromTo(+currentPage-1, paginatorJump - 2, currentPage))
  }else{
    if(+currentPage !== pages && !( +currentPage + paginatorJump > pages )){
      defaultPaginationState.links?.push(...createFromTo(1, paginatorJump - 2, +currentPage))
    }else{
      if(pages > paginatorJump && +currentPage > paginatorJump){
        defaultPaginationState.links?.push(...createFromTo(currentPage - paginatorJump, paginatorJump, currentPage))
      }else{
        if (pages > 0)
          defaultPaginationState.links?.push(...createFromTo(1, paginatorJump, currentPage))
      }
    }
  }
  
  if (+currentPage !== pages && pages > paginatorJump && !(+currentPage + paginatorJump > pages)){
    defaultPaginationState.links?.push({active: false, label: `...`, page: null , url: null})
    defaultPaginationState.links?.push({active: false, label: `${pages}`, page: pages , url: `/?page=${pages}`})
  }
  
  if (+currentPage < pages)
    defaultPaginationState.links?.push({active: false, label: "Next &raquo;", page: +currentPage!==+pages?+currentPage+1:null, url: null})

  defaultPaginationState.page = +currentPage
  
  
  return defaultPaginationState
}

const createFromTo = (from:number, pageJumper: number, currentPage: number):any => {
    let arr = []
    for (let i = from; i < from+pageJumper; i++) {
      if (i+1 === currentPage && i !== 0 ){
        arr.push({active: true, label: `${i+1}`, page: i+1 , url: `/?page=${i+1}`})
      }else{
        arr.push({active: false, label: `${i+1}`, page: i+1 , url: `/?page=${i+1}`})
      }
    }
    return arr;
}