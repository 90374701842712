import { User } from '@apuestes/types';
import { createSlice } from '@reduxjs/toolkit';
import { apuestesAPI } from '../../services/apuestesAPI'
import { initialUserState } from '../../../../_metronic/helpers';

const userSlice = createSlice({
  name: 'user',
  initialState: { 
    meta: { fetch: false, id: -1 }, 
    value: initialUserState 
  },
  reducers: {},
  extraReducers(builder) {
    builder
    .addMatcher(apuestesAPI.endpoints.getModelById.matchFulfilled, (state, action) => {
      if (action.meta.arg.originalArgs.model === 'users') {
          state.value = action.payload as User;
      }
    })
    .addMatcher(apuestesAPI.endpoints.updateModel.matchFulfilled, (state, action) => {
      if (action.meta.arg.originalArgs.model === 'users') {
          state.value = Object.assign(state.value, action.payload as User);
      }
    })
    .addMatcher(apuestesAPI.endpoints.setMedia.matchFulfilled, (state, action) => {
      if (action.meta.arg.originalArgs.model === 'users') {
          state.value = Object.assign(state.value, action.payload as User);
      }
    })
  },
});

export default userSlice.reducer;
