import React from 'react';
import ReactDOM from 'react-dom/client';
//Redux
import { Provider } from 'react-redux';
import { store } from './app/store';
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js';
import {QueryClient, QueryClientProvider} from 'react-query';
// Internationalization
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n';
import { MomentLocaleProvider } from './_metronic/i18n/MomentLocaleProvider'
//Metronic Styles
import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/plugins.scss';
import './_metronic/assets/sass/style.react.scss';
import 'react-toastify/dist/ReactToastify.min.css';
//Overriding toast styles
import './toast/index.scss';
//App modules
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'

setupAxios(axios)
Chart.register(...registerables)

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <MetronicI18nProvider>
              <MomentLocaleProvider>
                <AuthProvider>
                  <AppRoutes />
                </AuthProvider>
              </MomentLocaleProvider>
          </MetronicI18nProvider>
        </QueryClientProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
