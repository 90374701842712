/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import { useIntl } from "react-intl";
import * as Yup from 'yup'
import clsx from 'clsx'
// import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {login} from '../core/_requests'
import {useAuth} from '../core/Auth'

const initialValues = {
  email: 'admin@demo.com',
  password: 'demo',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const intl = useIntl();
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: "AUTH.VALIDATION.EMAIL_INVALID_FORMAT" }))
      .min(3, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }).replace('{min}', '3'))
      .max(50, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH" }).replace('{max}', '50'))
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED" }).replace('{name}', 'Email')),
    password: Yup.string()
      .min(3, intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }).replace('{min}', '3'))
      .max(50, intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH" }).replace('{max}', '50'))
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED" }).replace('{name}', intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" }))),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        if (auth.user?.roles.find( (role:any) => role.name === "ADMIN" ) ) {
          saveAuth(auth)
          // const {data: user} = await getUserByToken()
          setCurrentUser(auth.user)
        }else{
          setStatus(intl.formatMessage({ id: "AUTH.LOGIN.VALIDATE_ADMIN" }))
          setLoading(false)
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus(intl.formatMessage({ id: "AUTH.LOGIN.VALIDATE_USER_DATA" }))
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({ id: "AUTH.LOGIN.MAIN_TITLE" })}</h1>
      </div>
      {/* begin::Heading */}

      {/* begin::Separator */}
      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>{intl.formatMessage({ id: "AUTH.GENERAL.WELCOME" })}</span>
      </div>
      {/* end::Separator */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}</label>
        <input
          placeholder={intl.formatMessage({ id: "AUTH.INPUT.EMAIL" })}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" })}</label>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}
        {/* <Link to='/auth/forgot-password' className='link-primary'>
          {intl.formatMessage({ id: "AUTH.GENERAL.FORGOT_BUTTON" })}?
        </Link> */}
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>{intl.formatMessage({ id: "AUTH.GENERAL.LOGIN_BUTTON" })}</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({ id: "AUTH.GENERAL.LOGIN_BUTTON_LOADER" })}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
