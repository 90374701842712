import { configureStore } from '@reduxjs/toolkit';
import tipsterTypeReducer from './features/tipster_type/tipster-type-slice';
import userReducer from './features/user/user-slice';
import sportReducer from './features/sports/sport-slice';
import eventReducer from './features/events/event-slice';
import marketReducer from './features/market/market-slice';
import bookieReducer from './features/bookie/bookie-slice';
import tipsterReducer from './features/tipster/tipster-slice';
import countryReducer from './features/country/country-slice';
import discountReducer from './features/discounts/discount-slice';
import serviceReducer from './features/service/service-slice';
import participantReducer from './features/participant/participant-slice';
import competitionReducer from './features/competition/competition-slice';
// Requests

//Apuestes API
import { apuestesAPI } from './services/apuestesAPI';

export const store = configureStore({
    reducer: {
        user: userReducer,
        event: eventReducer,
        sport: sportReducer,
        bookie: bookieReducer,
        market: marketReducer,
        tipster: tipsterReducer,
        country: countryReducer,
        service: serviceReducer,
        discount: discountReducer,
        tipsterType: tipsterTypeReducer,
        participant: participantReducer,
        competition: competitionReducer,
        [apuestesAPI.reducerPath]: apuestesAPI.reducer,
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
        serializableCheck: {
            ignoreState: true
        }
    })
    .prepend(apuestesAPI.middleware)
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
