import { Discount } from "@apuestes/types";
import { apuestesAPI } from "../../services/apuestesAPI";
import { createSlice } from "@reduxjs/toolkit";
import { initialDiscountState } from "../../../../_metronic/helpers";

const discountSlice = createSlice({
    name: "discount",
    initialState: {
        meta: { fetch: false, id: -1 },
        value: initialDiscountState
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(apuestesAPI.endpoints.getModelById.matchFulfilled, (state, action) => {
                if (action.meta.arg.originalArgs.model === 'discounts') {
                    state.value = action.payload as unknown as Discount;
                }
            })
            .addMatcher(apuestesAPI.endpoints.updateModel.matchFulfilled, (state, action) => {
                if (action.meta.arg.originalArgs.model === 'discounts') {
                    state.value = action.payload as unknown as Discount;
                }
            })
    }
});

export default discountSlice.reducer;