import { Country } from "@apuestes/types";
import { apuestesAPI } from "../../services/apuestesAPI";
import { createSlice } from "@reduxjs/toolkit";
import { initialCountryState } from "../../../../_metronic/helpers";

const countrySlice = createSlice({
    name: "country",
    initialState: {
        meta: { fetch: false, id: -1 },
        value: initialCountryState
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(apuestesAPI.endpoints.getModelById.matchFulfilled, (state, action) => {
                if (action.meta.arg.originalArgs.model === 'countries') {
                    state.value = action.payload as Country;
                }
            })
            .addMatcher(apuestesAPI.endpoints.updateModel.matchFulfilled, (state, action) => {
                if (action.meta.arg.originalArgs.model === 'countries') {
                    state.value = action.payload as Country;
                }
            })
    }
});

export default countrySlice.reducer;