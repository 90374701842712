import { User, Sport, TipsterType, Participant, Country, Competition, Bookie, Event, Market, Service, Discount } from '@apuestes/types';

  const initialUserState: User = {
    id: -1,
    firstname: null,
    lastname: null,
    email: '',
    alias: null,
    age: null,
    auth_provider: null,
    dni: null,
    address: null,
    telf: null,
    telegram_id: null,
    telegram_channel: null,
    twitter_id: null,
    instagram_id: null,
    country_id: null,
    username: null,
    password: null,
    email_verified_at: null,
    last_login: null,
    photo_id: null,
    verify_hash: null,
    balance: 0,
    base_id: 0,
    metrics_id: 0,
    channel_id: 0,
  }

  const initialTipsterTypeState: TipsterType = {
    id: -1,
    name: '',
    is_free: false,
    picks_min: 0,
    services_max: 0,
    eligible: 0,
    base_id: 0,
  }

  const initialSportState: Sport = {
    id: -1,
    logo_id: null,
    name: 'sport',
    base_id: 0,
    pick_result_types: []
  }

  const initialParticipantState: Participant = {
    id: -1,
    photo_id: null,
    name: 'participant',
    base_id: 0
  }

  const initialCountryState: Country = {
    id: -1,
    flag_id: null,
    name: 'country',
    base_id: 0
  }

  const initialDiscountState: Discount = {
    id: -1,
    tipster_id: -1,
    code: '',
    description: null,
    starts_at: new Date(),
    ends_at: null,
    amount: 0,
    percentage: 0,
    spots: 0,
    active: true,
    base_id: -1,
  }

  const initialCompetitionState: Competition = {
    id: -1,
    logo_id: null,
    countries_sports_id: -1,
    name: 'competition',
    base_id: 0
  }

  const initialBookieState: Bookie = {
    id: -1,
    name: 'bookie',
    icon_id: null,
    url: '',
    is_verified: false,
    base_id: 0
  }

  const initialMarketState: Market = {
    id: -1,
    name: 'market',
    base_id: 0
  }

  const initialEventState: Event = {
    id: -1,
    user_id: 0,
    manual: false,
    algolia_id: -1,
    expired: false,
    date: new Date(),
    is_live: false,
    competition_id: -1,
    base_id: -1,
    result: ''
  }

  const initialServiceState: Service = {
    id: -1,
    name: '',
    description: '',
    base_id: -1,
    logo_id: -1,
    eligible: -1,
    users_tipsters_types_id: -1,
    spots: -1,
    price: -1,
    has_bet365: false,
    paused: false,
    can_pause: false,
    can_limit_spots: false,
    can_modify_price: false,
    suscriptions: -1,
    coins: -1,
    metrics_id: 0,
    hash: '',
  }

  export {
    initialUserState,
    initialSportState,
    initialEventState,
    initialBookieState,
    initialMarketState,
    initialCountryState,
    initialServiceState,
    initialDiscountState,
    initialTipsterTypeState,
    initialParticipantState,
    initialCompetitionState
  }