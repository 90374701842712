import { Bookie } from '@apuestes/types';
import { createSlice } from '@reduxjs/toolkit';
import { initialBookieState } from '../../../../_metronic/helpers';
import { apuestesAPI } from '../../services/apuestesAPI';

const bookieSlice = createSlice({
    name: 'bookie',
    initialState: {
        meta: { fetch: false, id: -1 },
        value: initialBookieState
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(apuestesAPI.endpoints.getModelById.matchFulfilled, (state, action) => {
                if (action.meta.arg.originalArgs.model === 'bookies') {
                    state.value = action.payload as Bookie;
                }
            })
            .addMatcher(apuestesAPI.endpoints.updateModel.matchFulfilled, (state, action) => {
                if (action.meta.arg.originalArgs.model === 'bookies') {
                    state.value = action.payload as Bookie;
                }
            })
    },
});

export default bookieSlice.reducer;