import { Service } from "@apuestes/types";
import { apuestesAPI } from "../../services/apuestesAPI";
import { createSlice } from "@reduxjs/toolkit";
import { initialServiceState } from "../../../../_metronic/helpers";

const serviceSlice = createSlice({
    name: "service",
    initialState: {
        meta: { fetch: false, id: -1 },
        value: initialServiceState
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(apuestesAPI.endpoints.getModelById.matchFulfilled, (state, action) => {
                if (action.meta.arg.originalArgs.model === 'services') {
                    state.value = action.payload as Service;
                }
            })
            .addMatcher(apuestesAPI.endpoints.updateModel.matchFulfilled, (state, action) => {
                if (action.meta.arg.originalArgs.model === 'services') {
                    state.value = action.payload as Service;
                }
            })
    }
});

export default serviceSlice.reducer;