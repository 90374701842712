import { TipsterType } from '@apuestes/types';
import { apuestesAPI } from '../../services/apuestesAPI';
import { createSlice } from '@reduxjs/toolkit';
import { initialTipsterTypeState } from '../../../../_metronic/helpers';

const tipsterTypeSlice = createSlice({
    name: 'tipsterType',
    initialState: {
        meta: { fetch: false, id: -1 }, 
        value: initialTipsterTypeState 
    },
    reducers: {},
    extraReducers(builder) {
        builder
            .addMatcher(apuestesAPI.endpoints.getModelById.matchFulfilled, (state, action) => {
                if (action.meta.arg.originalArgs.model === 'tipster-types') {
                    state.value = action.payload as TipsterType;
                }
            })
            .addMatcher(apuestesAPI.endpoints.updateModel.matchFulfilled, (state, action) => {
                if (action.meta.arg.originalArgs.model === 'tipster-types') {
                    state.value = action.payload as TipsterType;
                }
            })
    },
});

export default tipsterTypeSlice.reducer;