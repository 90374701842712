/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useMemo, useRef, useState} from 'react'
import clsx from 'clsx'
import moment from 'moment'
import { useIntl } from 'react-intl'
import { Media, Message } from '@apuestes/types'
import { MenuComponent } from '../../assets/ts/components'
import { onSendValues, stringifyRequestQuery } from '../../helpers'
import { useQueryRequest } from '../../../app/modules/accounts-tipster/components/core/QueryRequestProvider'
import { useDeleteModelMutation, useListModelQuery, useSetModelMutation } from '../../../app/store/services/apuestesAPI'
import { AudioMedia, ImageMedia } from './Media'

type Props = {
  isDrawer?: boolean
  channel_id: number,
}

const ChatInner: FC<Props> = ({isDrawer = false, channel_id }) => {
  const intl = useIntl()
  const { state } = useQueryRequest()
  const scroll = useRef<HTMLDivElement>(null)
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState<Array<Message & { medias?: Array<Media> }>>([])
  const [sendMsg, { isSuccess }]  =  useSetModelMutation();
  const [deleteMSG, { isSuccess: deleted }] = useDeleteModelMutation();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(Object.assign(state, { filter: { fresh: true } })))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const { data: msg, refetch: refetchMsg, isSuccess: isSuccessMsg } = useListModelQuery({ model: `channels/bo/${ channel_id }/messages`, query, includes: 'medias,pick,service' })


  const sendMessage = async () => {
    //Send message to api
    await onSendValues(
      `channels/bo/channel/${channel_id}/message`,
      {
        service_id: null,
        pick_id: null,
        content: message,
        is_public: true,
        channel_id: 1,
        created_at: new Date(),
        id: 9999
      },
      [], sendMsg
    )
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  const switchMedia = ({ extension, filename, type }: Media, idx?: number) => {
    switch(extension) {
      case 'jpg' || 'jpeg':
        return <ImageMedia key={ idx } extention={ extension } name={ filename } type={ type } idx={ idx } />;
      case 'mp3':
        return <AudioMedia key={ idx } extention={ extension } name={ filename } type={ type } idx={ idx } />;
      default:
        return;
    }
  }

  useEffect(() => {
    
    if ( isSuccessMsg ) {
      setMessages((msg.data as Array<Message>))
    }
    
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
    
    if ( isSuccess ) {
      setMessage('')
      refetchMsg()
    }
    
    if (deleted ) {
      refetchMsg()
    }

    if ( isSuccessMsg && messages ) {
      setTimeout(() => {
        if (scroll.current ) 
          scroll.current.scrollTop = scroll.current.scrollHeight;
        MenuComponent.reinitialization()
      }, 500)
    }


  }, [ msg, isSuccess, deleted, refetchMsg, isSuccessMsg, messages, scroll, query, updatedQuery ])

  return (
    <div
      className='card-body'
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
    >
      
      <div
        className={clsx('scroll-y me-n5 pe-5 h-300px')}
        ref={ scroll }
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='225px'
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
        }
      >
        { messages.map((message, index) => {
          const state = message.is_public ? 'success' : 'warning'
          const templateAttr = {}
          const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${
            true ? 'end' : 'start'
          } mb-10`
          return (
            <div
              key={`message${index}`}
              className={clsx('d-flex', contentClass, 'mb-10')}
              {...templateAttr}
            >
              <div
                className={clsx(
                  'd-flex flex-column align-items',
                  `align-items-${true ? 'end' : 'start'}`
                )}
              >
                <div
                  className={clsx(
                    'd-flex align-items-center justify-content-end flex-wrap p-5 rounded',
                    `bg-light-${ state }`,
                    'text-dark fw-bold mw-lg-400px',
                    `text-${ true ? 'end' : 'start'}`
                  )}
                  data-kt-element='message-text'
                >
                  <div className="w-100 d-flex align-items-center justify-content-between">
                    <p className='mb-0'>{ message.content }</p>
                    <div className='message-toolbar'>
                      <div className='ms-2'>
                        <button
                          className='btn btn-sm btn-icon btn-active-light-primary'
                          data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                        >
                          <i className='bi bi-three-dots fs-3'></i>
                        </button>
                        {/* begin::Menu */}
                        <div
                          className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-200px py-4'
                          data-kt-menu='true'
                        >
                          {/* begin::Menu item */}
                          <div className='menu-item px-3'>
                            <a
                              className='menu-link px-3'
                              onClick={async () => await deleteMSG({ model: `channels/bo/channel/${ channel_id }/message`, id: message.id })}
                              >
                              { intl.formatMessage({ id:'USER.PROFILE.OVERVIEW.ACTIONS.ACTION_DELETE' }) }
                            </a>
                          </div>
                          {/* end::Menu item */}
                        </div>
                        {/* end::Menu */}
                      </div>
                    </div>
                  </div>
                  {
                    (message.medias && message.medias.length > 0) && (
                      message.medias.map( (media, idx) => (
                        switchMedia(media, idx)
                      ) )
                    )
                  }
                </div>
                <div className='text-muted'>{ moment(message.created_at).format('MMMM Do YYYY HH:mm').toString() }</div>
              </div>
            </div>
          )
        }) }
      </div>

      <div
        className='card-footer pt-4'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <textarea
          className='form-control form-control-flush mb-3'
          rows={1}
          data-kt-element='input'
          placeholder='Type a message'
          value={ message }
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={ onEnterPress }
        ></textarea>

        <div className='d-flex flex-stack'>
          <div className='d-flex align-items-center me-2'>
            {/* <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              <i className='bi bi-paperclip fs-3'></i>
            </button>
            <button
              className='btn btn-sm btn-icon btn-active-light-primary me-1'
              type='button'
              data-bs-toggle='tooltip'
              title='Coming soon'
            >
              <i className='bi bi-upload fs-3'></i>
            </button> */}
          </div>
          <button
            className='btn btn-primary'
            type='button'
            data-kt-element='send'
            onClick={sendMessage}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  )
}

export {ChatInner}
